import type { FC, ReactNode } from 'react';
import React, { memo } from 'react';
import { styled } from '@compiled/react';
import isEqual from 'lodash/isEqual';

import { token } from '@atlaskit/tokens';
import { N200 } from '@atlaskit/theme/colors';

import {
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system/side-nav/menu-section';

import type { Space } from '@confluence/space-utils/entry-points/useSpacesData';

import { SpacesDropdownItem } from './SpacesDropdownItem';

type SpacesDropdownSectionProps = {
	listId: string;
	isNav4Enabled: boolean;
	heading?: ReactNode;
	spaces?: Space[];
	currentSpace?: Space;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionContainer = styled.div({
	margin: `${token('space.200', '16px')} 0 ${token('space.100', '8px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		marginBottom: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionHeading = styled.h2({
	color: token('color.text.subtle', N200),
	textTransform: 'uppercase',
	font: token('font.body.small', '11px'),
	fontWeight: token('font.weight.bold', '700'),
	margin: `0 0 ${token('space.100', '8px')} ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpaceList = styled.ul({
	listStyleType: 'none',
	width: '100%',
	padding: 0,
	margin: 0,
});

const SECTION_TITLE_ID_PREFIX = 'spaces-dropdown-section-header-';

export const SpacesDropdownSection: FC<SpacesDropdownSectionProps> = memo(
	({ listId, isNav4Enabled, heading, spaces, currentSpace }) => {
		const isSpaceAliasFFEnabled = true;
		const spaceList = (
			<SpaceList aria-labelledby={`${SECTION_TITLE_ID_PREFIX}${listId}`}>
				{currentSpace && (
					<SpacesDropdownItem
						space={currentSpace}
						key={(isSpaceAliasFFEnabled && currentSpace.alias) || currentSpace.id!}
						listId={listId}
						isNav4Enabled={isNav4Enabled}
					/>
				)}
				{spaces &&
					spaces.map((space) => {
						return (
							<SpacesDropdownItem
								space={space}
								key={space.id}
								isNav4Enabled={isNav4Enabled}
								listId={listId}
							/>
						);
					})}
			</SpaceList>
		);

		if (isNav4Enabled) {
			return (
				<MenuSection>
					{heading && <MenuSectionHeading>{heading}</MenuSectionHeading>}
					{spaceList}
				</MenuSection>
			);
		}

		return (
			<SectionContainer>
				{heading && (
					<SectionHeading id={`${SECTION_TITLE_ID_PREFIX}${listId}`}>{heading}</SectionHeading>
				)}
				{spaceList}
			</SectionContainer>
		);
	},
	isEqual,
);
